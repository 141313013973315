.footer-container {
  background-color: #EFEFEF;
  color: '#3C3C3C';
  font-size: 15px;
  padding: 5% 10% 2% 10%;
}
.list-topic {
  margin-bottom: 20px;
  color: #5D5D5D;
  cursor: default;
}
.main-topic {
  color: #3C3C3C;
  font-weight: 600;
  margin-bottom: 30px;
}
.flex-row-container {
  display: flex;
  flex-direction: row;
}
.flex-column-container {
  display: flex;
  flex-direction: column;
}
.footer1
{
  display:flex;
  flex-direction:row;
  justify-content:space-evenly;
}
.courses
{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
}
.courses > div
{
  padding:1% 10%;
}
.address-main
{
  background-color: darkred;
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.we-provide
{
  display:flex;
  flex-direction: column;
}
.we-provide > div
{
  padding:1% 10%;
}
.footer2
{
  display:flex;
  flex-direction:row;
  justify-content: space-between;
  margin-bottom: 3%;
  margin:3% 15%;
}
.are-you-a-teacher
{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.with-us
{
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.address
{
  justify-content: end;
}
.products
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.products > div
{
  margin: 5px;
}
.engineering
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.engineering >  div
{
  margin:3px;
}
.medical
{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.medical >div
{
  margin:5px;
}
.footer1-mobile
{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  margin:5%;
}
.footer2-mobile
{
  justify-content: center;
  display:flex;
}
.connect-with-us-mobile{
    color: '#3C3C3C';
    font-weight: bold;
    font-family: 'Nunito Sans';
    margin-left: 10px;
}
.footer3-mobile
{
  display:flex;
  flex-direction:row;
  justify-content: center;
  margin-bottom: 3%;
  margin:3%;
  flex-wrap: wrap;
}
.footer3-mobile>div
{
  white-space: nowrap;
  margin: 1%;
}

