.home-text  {
    font-family: Helvetica;
}
.feature-combine-tags {
    display: flex;
    flex-direction: row;
    margin-bottom: 5%;
}
.button-div {
    margin: 30px 90px;
    width: 30%;
}
.main-heading {
    color: #454545;
    font-size: 48px;
    font-weight: 700;
    margin:30px 30px 5px 30px;
    text-align: center;
}
.sub-heading {
    color:#5D5D5D;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
    margin: 0px 40px 0px 40px;
}
.combine-views-main {
    display: flex;
    flex-direction: column;

}

.combine-views-sub {
    display: flex;
    flex-direction: column;
}
.topic-heading {
    display: flex;
    flex-direction: column;
    text-align: center;
}
.content-display {
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
}
.content-display-left {
    font-size: 16px;
    display:flex;
    flex-direction: row;
    padding:5% 5% 0% 5%;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: center
}
.content-display-right {
    width: 50%;
    padding:5% 5% 0% 0%;
}
.feature-description {
    margin-left: 20px;
    text-align: left;
}
.modal-align {
    display: 'flex';
    justify-content: 'center';
    text-align: left;
}
.modal-button {
    text-align: center;
    background-color: #6c5ce7;
    color: #FFFFFF;
    height: 45px;
    width: 240px;
    border-radius: 4px;
    font-size: 18px;
}
.join-institute {
    width: 100%;
    align-self: flex-end;
    text-align: left;
    align-self: center;
    margin-top: 10px;
}
.content-primary {
    font-size: 18px;
    color: #212121;
    font-weight: 700 !important;
}
.content-secondary {
    font-size: 16px;
    color: #454548;
}

.highlight-primary {
    font-size: 18px;
}
.highlight-secondary {
    font-size: 16px;
}
.carousel {
    width: 100%;
  }
.dynamic-view {
    flex-direction: row;
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin: 2% 8%;
}
.dynamic-flex {
    text-align: center;
}
.institute-heading {
    font-size: 30px;
    font-weight: 700;
    width: 75%;
    padding: 0% 8%;
}
.institute-description {
    font-size: 16px;
    width: 65%;
    padding: 2% 8%;
    color: #5D5D5D;
}
.institute-display {
    display: flex;
    flex-direction: row;
    padding-left: 8%;
}

.half-background {
    width: 100%;
    height: 60%;
}

.institute-logo {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-content: center;
    align-items: center;
    color: #808080;
}

.institute-logo >div {
    height: 30px;
}
.institute-display >div {
    width: 100%;
}

.modal-trynow-button {
    margin:30px 90px;
    width:40%;
}
.heading-text {
    font-size: 40px;
    font-weight: 800;
    padding: 142px 7% 0px 8%;
    justify-content: flex-start;
    margin-bottom: 2%
}
.dynamic-val {
    font-size: 40px;
    font-weight: 600 !important;
    padding: 0%;
    justify-content: flex-start;
    margin-bottom: 2%
}
.dynamic-topic {
    color: #5D5D5D;
    font-size: 16px;
}
.sub-headings {
    font-size: 32px;
    padding:94px 0px 0px 0px;
}
.video-link {
    width:50%;
    padding:6% 4% 4% 10%;
}
.desktop-link {
    width:50%;
    padding:6% 4% 0% 10%;
}
.logo {
    height: 100%;
    width:100%;
}
.text-over-image {
    position: absolute;
    top: 40;
    left: 50%;
}
.carousel-main {
    scroll-snap-type: x mandatory;	
	-webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -ms-inline-box;
}

.scroll-snap {
    scroll-snap-align: start;
    scroll-snap-stop: normal;
    overflow-x: scroll;
}
.all-devices-font {
    left: 0; 
    right: 0; 
    display: flex;
    justify-content: center;
    font-size: calc(2rem + 2vw);
    font-weight: 600;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  td,
  th {
    padding: 0;
  }

@media screen and (min-height: 700px) and (min-width: 768px)  {
    /* .snap {
        scroll-snap-align: start;
        scroll-snap-stop: normal;
        height:calc( 100vh - 5em );
    }  */
}
@media screen and (max-width: 768px)  {
    .modal-align {
        text-align: center;
        margin: 0%;
    }
    .institute-logo {
        width: 100%;
        height: 100%;
        margin: 2%;
        justify-content: center;
    }
    .logo {
        height: 150%;
        width:100%;
    }
    .institute-heading {
        width: 100%;
    }
    .institute-description {
        width: 100%
    }
    .institute-display {
        padding-left: 0;
    }
    .main-heading {
        font-size: 36px;
    }
    .sub-heading {
        font-size: 20px;
    }
    .combine-views-sub {
        flex-direction: column-reverse;
    }
    .join-institute {
        text-align: center;
    }
    .content-display {
        flex-direction: column-reverse;
    }
    .content-display-left {
        width:100%;
        font-size: 14px;
    }
    .content-display-right {
        width: 100%;
        height: auto;
        padding: 5%;
    }
    .primary-button {
        padding : 5% 0% 
    }
    .content-primary, .highlight-primary {
        font-size: 16px;
    }
    .content-secondary, .highlight-secondary {
        font-size: 14px;
    }
    .dynamic-topic {
        font-size: 14px;
    }
    .dynamic-val {
        font-size: 30px;
    }
    .dynamic-view {
        flex-direction: column;
        text-align: center;
    }
    .dynamic-flex {
        width: 100%;
        margin-bottom: 20px;
    }
    .institute-display {
        flex-direction: column-reverse;
    }
}


