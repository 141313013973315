body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}

.bullet {
  border-radius: 50%;
  width: 8px; 
  height: 8px;
  box-sizing: border-box;
  background-color: #000;
}

.logo {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;
}
.static-pages {
  font-family: 'Nunito';
}

.static-pages  .header {
  font-family: 'Nunito Sans', sans-serif;
}
