@media only screen and (max-width: 769px) {
  body {
    overflow-x: hidden;
  }
  div .header-size {
    font-size: 28px;
    margin-top: 30px;
  }
  div .header-size-secondary {
    font-size: 26px;
  }
  div .header-bottom {
    font-size: 16px;
    text-align: center;
  }
  div .culture-flex img {
    height: 90px;
    width: 90px;
  }
  div .perks-flex img {
    height: 50px;
    width: 50px;
  }
  div .content-header-size {
    font-size: 18px;
    padding-bottom: 10px;
  }
  div .content-content-size {
    font-size: 14px;
    padding-bottom: 10px;
  }
  div .culture-flex {
    margin: 0px 50px;
    padding: 40px 0px 0px 0px;
    height: auto;
    width: auto;
  }
  div .left-pad {
    margin: 20px;
    padding-left: 0.7em;
  }

  div .details {
    font-size: 28px;
  }
  div .details-sub-header {
    font-size: 19px;
  }
  div .details-content {
    font-size: 14px;
    white-space: pre-wrap;
  }
  div .start {
    padding: 0px 20px;
  }
  div .space {
    padding: 0px 20px;
  }
  div .openings-button {
    width: 100%;
    height: auto;
  }
  div .individual-button {
    width: 100%;
  }

  div .perks-flex {
    padding-left: 11%;
  }

  div .button-text-size {
    font-size: 14px;
  }
  div .input {
    width: 100%;
  }
  div .textarea {
    width: 100%;
  }
  div .form-row .name {
    width: 100%;
  }
  div .attach-btn {
    width: 200px;
  }
}

body {
  background: #ffffff;
}
.left-pad {
  padding-left: 20%;
}
.start {
  background: #ffffff;
}
.openings-button {
  height: auto;
  width: 100%;
  border: 0.5px solid #3c3c3c;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 15px;
  font-size: 15px;
  padding: 10px 15px;
}

.openings-button:hover {
  background: #6c5ce7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px rgba(108, 92, 231, 0.3);
  border: 0.5px solid #6c5ce7;
  border-radius: 4px;
  opacity: 1;
  color: #ffffff;
}
.button:hover {
  background: rgba(85, 72, 185, 1) 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 15px rgba(93, 79, 203, 0.5);
border: 0.5px solid rgba(108, 92, 231, 1);
border-radius: 4px;
opacity: 1;
}
button:focus {
  outline: none;
}
.button {
  top: 445px;
  left: 600px;
  width: 200px;
  height: 50px;
  background: rgba(108, 92, 231, 1) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px rgba(108, 92, 231, 0.3);
  border: 0.5px solid rgba(108, 92, 231, 1);
  border-radius: 4px;
  opacity: 1;
}
.header-bottom {
  font-size: 18px;
}
.content-header-size {
  font-size: 18px;
  padding-bottom: 10px;
}
.content-content-size {
  font-size: 16px;
  padding-bottom: 10px;
}
.culture-flex img {
  height: 175px;
  width: 175px;
}
.perks-flex img {
  height: 50px;
  width: 50px;
}
.header-size {
  font-size: 70px;
  margin-top: 100px;
}
.header-size-secondary {
  font-size: 32px;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0px;
}
.row {
  align-items: center;
  justify-content: center;
}

.flex-container-content {
  padding: 0;
  margin: 0;
  list-style: none;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
}
.flex-item {
  background: none;
  width: auto;
  height: 60px;
  margin: 10px;

  line-height: 20px;
  text-align: center;
}
.culture-flex {
  margin: 0px 50px;
  padding: 40px 0px 0px 0px;
  height: auto;
  width: 297px;
}
.perks-flex {
  margin: 0px 50px;
  padding: 24px 48px;
  height: auto;
  width: 420px;
  text-align: left;
}
.row:before, .row:after {display: none !important;}

.wrap {
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
}

.opening {
  display: grid;
}
.flex-bottom-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.link-to-page {
  justify-content: left;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.individual-button {
  margin-right: 5%;
  width: 40%;
}

.button-heading-size {
  font-size: 21px;
  font-weight: 700;
}
.details {
  font-size: 40px;
  margin-top: 40px;
  text-align: center;
  color: #454545;
}
.details-sub-header {
  margin-bottom: 15px;
  margin-top: 35px;
  font-size: 18px;
  color: #3c3c3c;
  font-weight: 700;
}
.details-content {
  font-size: 16px;
  white-space: pre-line;
  color: #5d5d5d;
}
.apply-btn {
  color: #ffffff;
  width: 200px;
  height: 40px;
  background: #6C5CE7 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px rgba(108, 92, 231, 0.3);
  border: none;
  border-radius: 4px;
  opacity: 1;
}

.apply-btn:hover {
  background: #5548b9 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 15px rgba(93, 79, 203, 0.3);
  border: none;
  border-radius: 4px;
  opacity: 1;
}
.bottom-pad {
  padding-bottom: 30px;
}

.arrow-img {
  background: url("/images/static_pages/right-arrow-black.svg") no-repeat;
  height: 10px;
  width: 10px;
}

.openings-button:hover .arrow-img {
  background: url("/images/static_pages/right-arrow-white.svg") no-repeat;
  height: 10px;
  width: 10px;
}
.outline-attach {
  background: url("/images/static_pages/outline-attach.svg") no-repeat;
  height: 24px;
  width: 18px;
}


 .name {
  color: #3C3C3C;
  font-size: 16px;
  font-weight: 600;
  margin-top: 11px;
  
}

 .value {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  width: 100%;
  
}
.input {
  width: 100%;
}
input {

top: 429px;
left: 572px;
width: 100%;
height: 40px;
background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #B1B1B1;
border-radius: 4px;
opacity: 1;

}
.attach-btn {
margin-top: 10px;
top: 357px;
left: 572px;
width: 225px;
height: 40px;
background: rgba(108, 92, 231, 0.2);
color: rgba(108, 92, 231, 1);
border: 1px solid #6C5CE7 ;
border-radius: 4px;
opacity: 1;
}
.textarea {
  width: 100%;
}
textarea {
  width: 100%;
  margin-top: 10px;
}
.contain-file-name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}


 