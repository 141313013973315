.about-us-web{
  display:grid;
  background-color:#F2F2F2; 
  font-family: Nunito Sans;
}
.problem-web-view{
  background-color: #FFFFFF;
  color: #3C3C3C;
  display:flex;
  flex-direction:column;
  text-align: center;
  font-weight:700;
  font-size:70px;
  padding-top:5%;
}
.problem-sub-heading-web-view
{
  font-family:Nunito Sans;
  font-size:18px;
  color:#5D5D5D;
  margin:3%;
  font-weight: 400;
}
.human-web-view{
  color:#FAFAFF;
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: #6C5CE7;
  padding-top: 3%;
  font-weight:600;
  font-size: 32px;
}
.human-sub-heading-web-view
{
  font-family:Nunito Sans;
  font-size:18px;
color:rgb(227, 227, 233);
padding-top:2%;
padding-bottom:4%; 
font-weight: 300;
}

.left-portion-web,.right-portion-web
{
  background-color: #F2F2F2;
  display: grid;
  grid-template-rows:290px 1fr;
}
.colored-portion
{
  background-color: #6C5CE7;
}
.employee-details-web
{
  background-color: #6C5CE7;
  display:grid;
  grid-template-columns: 1fr 5fr 1fr;
}
.main-container
{
  padding:3% 6% 5% 6%;
  padding-bottom:5%;
  border-top-left-radius: 0.4%;
  border-top-right-radius: 0.4%;
  background-color: #FFFFFF;
  box-shadow: 0 3px 10px -9px #000000;
}
.title
{
  color:#3C3C3C;
  text-align:center;
  display:grid;
  font-size: 18px;
  font-weight: 700;
}
.image-container-web{
  margin:2%;
  display:flex;
  flex-wrap:wrap;
  justify-content: center;
}
.text-over-image-web
{
  text-align: center;
  position: relative;
  margin: 1% 2.1% 3% 2.1%;
  }
.image-web
{
  border-radius: 5px;

  height: 260px;
   width: 200px;
  position: relative;
}
/*ffffff00*/
.layer {
  border-radius: 5px;
  background-image: linear-gradient(to bottom, rgba(248, 248, 248, 0),#171717);
  opacity:100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.name-web {
  font-family:Nunito ;
  color: #FAFAFF;
  position: absolute;
  font-size: 14px;
  font-weight: 700;
  left:10px;
  bottom:36px; 
}
.post-web
{
  font-family:Nunito ;
  left:10px;
  bottom:10px;
  color:#F6F3FF;
  position: absolute;
  font-size: 12px;
  font-weight: 300;
}

.problem-mobile-view
{
  background-color: #FFFFFF;
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  color: #3C3C3C;
  font-size: 28px;
  font-weight: 800;
  padding-top: 10%;
}
.problem-sub-heading-mobile-view
{
  font-family:Nunito Sans;
color: #5D5D5D;
font-size: 16px;
font-weight: 400;
margin: 12%;
}
.human-mobile-view{
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-align:center;
  background-color: #6C5CE7;
  color: #FAFAFF;
  font-size: 26px;
  font-weight: 700;
  padding-top: 14%
}
.human-sub-heading-mobile-view
{
  font-family:Nunito Sans;
  color:#FFFFFF;
  font-size: 16px;
  font-weight: 300;
  padding: 9%;
}
.employee-details
{
  background-color: #F2F2F2;
}
.overflow
{
  background-color: #6C5CE7;
  width:100%;
  height:280px;
  position: absolute;
}
.details-display
{
  position: relative;
  margin-left: 24px;
  margin-right: 24px;
  border-radius: 5px;
  box-shadow: 0 3px 10px -9px #000000;
  background-color:#FFFFFF;
  display: flex;
  flex-direction: column;
}
.menu
{
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  background-color:#FFFFFF;
  display: flex;
  justify-content:space-between;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 3px 10px -9px #000000;
}
.menu-items
{
  border-right-style: solid;
  border-right-width: 1px;
  border-right-color: rgb(219, 209, 209);
  white-space: nowrap;
  font-size: 12px;
  padding-right: 4%;
  margin: 5%;
}
.menu-items1
{
  color:#3C3C3C;
  font-weight:600;
  border-bottom-style:inset;
  border-bottom-color:#6C5CE7;
  border-bottom-width: 3px;
  border-bottom-left-radius: 10%;
  border-bottom-right-radius: 10%;
}

.menu-items2
{
  color:#5D5D5D;
  font-weight:300;
}
.image-container-mobile{
  display:flex;
  flex-wrap:wrap;
  margin:10%;
  justify-content: center;
  border-radius:10px;
  overflow: hidden;
}
.text-over-image-mobile
{
  
  padding: 20px;
  text-align: center;
  position: relative;
  border-radius: 20px 20px;
}
.image-mobile
{
  vertical-align: text-bottom;  
  width: 150px;
  height: 200px;
  border-radius: 5px 5px;
}
.name-mobile {
  color: #FAFAFF;
  font-family:Nunito;
  position: absolute;
  bottom:0px;
  font-size: 14px;
  font-weight: 700;
  left:35px;
  bottom:60px;
}
.post-mobile
{  
  font-family:Nunito;
  left:35px;
  bottom:35px;
  color:#F6F3FF;
  position: absolute;
  font-size: 12px;
  font-weight: 300;
}
