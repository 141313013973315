
.terms-header {
    font-size: 1.17em;
    font-weight: 700;
    color: #454548;
    margin-top: 25px;
}

.terms-body {
    margin-top: 5%;
    margin-left: 10%;
    margin-right: 10%;
    font-family: Lato; 
    font-size: 14px;
    font-weight: 400;
}


@media screen and (max-width: 768px) {
    .terms-body{
        font-size: 16px;
    }
}